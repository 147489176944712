import * as React from 'react';

import {
    graphql, HeadProps
} from 'gatsby';

import azAnimation from '../../assets/images/azure_animation.png';
import dtAnimation from '../../assets/images/databricks_animation.png';
import { Head as DefaultHead } from '../../components/head/head.component';
import ExpertisePage from '../../components/shared/expertise/expertise';
import { seo } from '../../config/seo-config';
import { GatsbyStrapiPictureField } from '../../models/cms-common';

export const query = graphql`
    query ExpertiseQuery($slug: String!) {
        strapiExpertises(slug: { eq: $slug }) {
            slug
            logo {
                localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            BlockATitle
            BlockADescription
            Presentation {
                title
                description
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            Advantages {
                title
                description
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }

            Lastblock {
                title
                description
                ctaText
                ctaLink
            }
        }
    }
`;

interface ExpertiseProps {
    data: {
        strapiExpertises: {
            slug: string;
            logo: GatsbyStrapiPictureField;
            BlockATitle: string;
            BlockADescription: string;
            Presentation: {
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            };
            Advantages: {
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            }[];
            Lastblock: {
                title: string;
                description: string;
                ctaText: string;
                ctaLink: string;
            }
        }
    }
}

const PartenairePage: React.FC<ExpertiseProps> = (props: ExpertiseProps) => {
    const slug = props.data.strapiExpertises.slug;
    let mainColor = '';
    let animation = '';
    if (slug === 'azure') {
        mainColor = '#018AD7';
        animation = azAnimation;
    } else if (slug === 'databricks') {
        mainColor = '#FF5949';
        animation = dtAnimation;
    } else {
        mainColor = '#ffffff';
    }

    return (
        <ExpertisePage animationImg={animation} mainColor={mainColor} data={props.data} />
    );
};

export default PartenairePage;

export const Head = ({ data }: HeadProps<{ strapiExpertises: { slug: string } }>) => {
    const { strapiExpertises: { slug } } = data;
    const seoData = seo.partenaire.children ? seo.partenaire.children[slug] : {};
    return (
        <DefaultHead {...seoData} />
    );
};