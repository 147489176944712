// extracted by mini-css-extract-plugin
export var animation = "expertise-module--animation--bcd1d";
export var azure = "expertise-module--azure--77d9c";
export var background_azure = "expertise-module--background_azure--e82a4";
export var background_databricks = "expertise-module--background_databricks--c2357";
export var blockA = "expertise-module--blockA--6a836";
export var blockB = "expertise-module--blockB--f2ebf";
export var blockC = "expertise-module--blockC--f9426";
export var databricks = "expertise-module--databricks--1362b";
export var fullHeight = "expertise-module--fullHeight--b2e3e";
export var page = "expertise-module--page--11d86";