import * as React from 'react';

import ReactMarkdown from 'react-markdown';

import * as styles from './expertise-presentation-block.module.css';

interface ExpertisePresentationBlockProps {
    title: string;
    description?: string;
    className?: string
}

export const ExpertisePresentationBlock: React.FC<ExpertisePresentationBlockProps> = ({
    title, description, className
}) => {

    return (
        <React.Fragment>
            <div className={`d-flex align-items-center justify-content-center ${styles.root} ${className}`}>
                <div className='row col-12 col-md-8 justify-content-center'>
                    <div className='col-12 mb-md-3 align-self-end'> 
                        {title && <h1 className={`${styles.title} text-center`}>{title}</h1>}
                    </div>
                    <div className='col-12 align-self-start text-center'>
                        {description &&
                            <ReactMarkdown className={styles.description}>{description}</ReactMarkdown>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

//row col-12 col-md-8 pt-0 mt-0 pt-md-5 mt-md-5 align-self-center justify-content-center