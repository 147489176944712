import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import {
    CtaProps,
    SimpleImageBlockProps
} from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { CtaButton } from '../../shared/cta-button/cta-button.component';
import { TitleBlock } from '../../shared/title-block/title-block.component';
import * as styles from './expertise-last-block.module.css';

interface ExpertiseLastBlockOwnProps {
    id?: string;
    mainColor?: string;
    slug: string;
}

type ExpertiseLastBlockProps = ExpertiseLastBlockOwnProps & SimpleImageBlockProps & CtaProps;

export const ExpertiseLastBlock: React.FC<ExpertiseLastBlockProps> = ({
    id,
    title,
    subtitle,
    description,
    ctaLink,
    ctaText,
    mainColor,
    slug
}: ExpertiseLastBlockProps) => {
    const color: string = mainColor || '#018AD7';
    return (
        <React.Fragment>
            <div id={id} className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
                <div className={`col-11 col-md-5 d-flex justify-content-center align-items-center ${styles.img}`}>
                    {slug === 'azure' ? (
                        <StaticImage
                            className='h-100 w-100'
                            src='../../../assets/images/azure_&.png'
                            alt='azure image'
                            objectFit='contain'
                        />
                    ) : (
                        <StaticImage
                            className='h-100 w-100'
                            src='../../../assets/images/databricks_&.png'
                            alt='azure image'
                            objectFit='contain'
                        />
                    )}
                </div>
                <div className=' mt-5 mt-md-0 col-11 col-md-5 d-flex justify-content-center align-items-center'>
                    <div>
                        {!isUndOrEmpty(title) && <TitleBlock borderColor={color}>{title}</TitleBlock>}
                        {!isUndOrEmpty(subtitle) && <h2>{subtitle}</h2>}
                        {!isUndOrEmpty(description) && (
                            <ReactMarkdown className={styles.description}>{description}</ReactMarkdown>
                        )}
                        {!isUndOrEmpty(ctaText) && (
                            <div className={styles.presBtnContainer}>
                                <CtaButton link={ctaLink} color={mainColor}>{ctaText}</CtaButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};