import * as React from 'react';

import {GatsbyImage} from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import { buildGatsbyStrapiImage } from '../../../utils';
import * as styles from './expertise-banner-block.module.css';

interface ExpertiseBannerBlockProps {
    subtitle?: string;
    description?: string;
    logo?: GatsbyStrapiPictureField;
}

export const ExpertiseBannerBlock: React.FC<ExpertiseBannerBlockProps> = ({
    subtitle, description, logo
}) => {

    const img = buildGatsbyStrapiImage(logo);

    return (
        <React.Fragment>
            <div className={styles.root}>
                <div className='row col-12 col-md-10 align-self-center justify-content-between'>
                    <div className='col-12 col-xl-7'>
                        <div className='row justify-content-center justify-content-md-start'>
                            <div className=''>
                                {img && (
                                    <GatsbyImage
                                        image={img}
                                        objectFit='contain'
                                        alt=""
                                    />
                                )}
                            </div>
                            
                        </div>
                        <div className='my-3'>
                            <div className='row my-3 p-0 text-center text-md-left'>
                                {subtitle && <h1 className={styles.subtitle}>{subtitle}</h1>}
                            </div>
                            <div className='row my-3 p-0 text-center text-md-left'>
                                {description &&
                                    <ReactMarkdown className={styles.description}>{description}</ReactMarkdown>}
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-xl-5'>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};