import * as React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import { buildGatsbyStrapiImage } from '../../../utils';
import * as styles from './expertise-advantages-block.module.css';

interface ExpertiseAdvantagesBlockProps {
    advantages: {
        title: string;
        description: string;
        picture: GatsbyStrapiPictureField;
    }[]
}

export const ExpertiseAdvantagesBlock: React.FC<ExpertiseAdvantagesBlockProps> = ({advantages}) => {

    return (
        <React.Fragment>
            <div className={`d-flex align-items-center justify-content-center py-5 my-5 ${styles.root}`}>
                <div className='row col-12 col-lg-8 mb-0 pb-0 mb-lg-5 pb-lg-5 justify-content-center'>
                    <div className='row mb-3 justify-content-center justify-content-lg-between'>
                        {
                            advantages.map((advantage, index) => {
                                const img = buildGatsbyStrapiImage(advantage.picture);
                                return (
                                    <div key={`${advantage.title}-${index}`} className='col-10 col-lg-3'>
                                        <div className='text-center'>
                                            {img && (
                                                <GatsbyImage
                                                    image={img}
                                                    objectFit='contain'
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div className='my-3 text-center'>
                                            <div className='row justify-content-center'>
                                                {advantage.title &&
                                                    <h1 className={styles.title}>{advantage.title}</h1>}
                                            </div>
                                            <div className='row justify-content-center'>
                                                {advantage.description &&
                                                    <ReactMarkdown className={styles.description}>
                                                        {advantage.description}
                                                    </ReactMarkdown>}
                                            </div>
                                        </div>

                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};