import * as React from 'react';

import { motion } from 'framer-motion';

import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import { ExpertiseLastBlock } from '../../page-home/expertise-last-block/expertise-last-block.component';
import { CustomHeader } from '../custom-header/custom-header.component';
import { ExpertiseAdvantagesBlock } from '../expertise-advantages-block/expertise-advantages-block.component';
import { ExpertiseBannerBlock } from '../expertise-banner-block/expertise-banner-block.component';
import { ExpertisePresentationBlock } from '../expertise-presentation-block/expertise-presentation-block.component';
import * as styles from './expertise.module.css';

interface ExpertiseProps {
    data: {
        strapiExpertises: {
            slug: string;
            logo: GatsbyStrapiPictureField;
            BlockATitle: string;
            BlockADescription: string;
            Presentation: {
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            };
            Advantages: {
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            }[];
            Lastblock: {
                title: string;
                description: string;
                ctaText: string;
                ctaLink: string;
            }
        }
    },
    animationImg: string;
    mainColor: string;
}

const ExpertisePage: React.FC<ExpertiseProps> = (props: ExpertiseProps) => {
    if (!props.data) return null;
    
    const {
        slug, BlockATitle, BlockADescription, logo, Presentation, Advantages, Lastblock
    } = props.data.strapiExpertises;
    
    const animationImg = props.animationImg;
    const mainColor = props.mainColor;

    return (
        <>
            <CustomHeader transparent={false} dark={true} backgroundColor="#000" />
            <div className={`${styles.page} ${styles[`${slug}`]}`}>

                <motion.section viewport={{ amount: 0.5 }}>
                    <section className={`row m-0 ${styles.blockA} ${styles.fullHeight}`}>
                        <ExpertiseBannerBlock
                            subtitle={BlockATitle}
                            description={BlockADescription}
                            logo={logo}
                        />
                    </section>
                </motion.section>

                <div>
                    <img
                        className={`d-none d-xl-block ${styles.animation}`}
                        src={animationImg}
                        alt={`${slug} animation`} />
                </div>

                <section className={`${styles[`background_${slug}`]}`}>
                    <motion.section viewport={{ amount: 0.5 }}>
                        <section className={`row p-0 m-0 ${styles.blockB} ${styles.fullHeight}`}>
                            <ExpertisePresentationBlock
                                title={Presentation.title}
                                description={Presentation.description}
                                className={`${slug === 'azure' ? 'pt-0 pt-md-25' : ''}`} />
                        </section>
                    </motion.section>

                    <motion.section viewport={{ amount: 0.5 }}>
                        <section 
                            className={
                                `d-flex 
                                ${slug === 'azure' ? 'align-items-start' : 'align-items-center'} 
                                ${styles.fullHeight}`
                            }>
                            <ExpertiseAdvantagesBlock
                                advantages={Advantages} />
                        </section>
                    </motion.section>

                </section>

                <section className={`my-5 ${styles.blockC} ${styles.fullHeight}`}>
                    <ExpertiseLastBlock
                        id='qui-sommes-nous'
                        picture={Presentation.picture}
                        title={Lastblock.title}
                        subtitle=''
                        description={Lastblock.description}
                        ctaLink={Lastblock.ctaLink}
                        ctaText={Lastblock.ctaText}
                        mainColor={mainColor}
                        slug={slug}
                    />
                </section>
            </div>
        </>
    );
};

export default ExpertisePage;
