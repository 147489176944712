// extracted by mini-css-extract-plugin
export var description = "expertise-banner-block-module--description--403fe";
export var greenCircle = "expertise-banner-block-module--greenCircle--bdd78";
export var img = "expertise-banner-block-module--img--19ad0";
export var imgItem = "expertise-banner-block-module--imgItem--02001";
export var logoTitle = "expertise-banner-block-module--logoTitle--766d1";
export var lower = "expertise-banner-block-module--lower--9f3fb";
export var motionDesign = "expertise-banner-block-module--motionDesign--f0806";
export var name = "expertise-banner-block-module--name--95606";
export var root = "expertise-banner-block-module--root--b69f0";
export var scrollIcon = "expertise-banner-block-module--scrollIcon--3e2c0";
export var subtitle = "expertise-banner-block-module--subtitle--1c2db";
export var title = "expertise-banner-block-module--title--d9cbf";
export var titleContainer = "expertise-banner-block-module--titleContainer--5c5e0";
export var upper = "expertise-banner-block-module--upper--51362";
export var upperLeft = "expertise-banner-block-module--upperLeft--18cf5";
export var upperRImg = "expertise-banner-block-module--upperRImg--0ee4e";
export var upperRight = "expertise-banner-block-module--upperRight--dbadf";
export var whiteBar = "expertise-banner-block-module--whiteBar--7f0cf";